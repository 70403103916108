var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-5",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"}),_c('div',{staticClass:"m-1"},[_c('vue-good-table',{attrs:{"columns":_vm.tableColumns,"rows":_vm.paginateData.data,"pagination-options":{
          enabled: true,
        },"mode":"remote","line-numbers":false,"theme":"polar-bear","isLoading":_vm.loading},on:{"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'client_id')?_c('span',[_vm._v(" "+_vm._s(props.row.client ? props.row.client.name_ar : "")+" ")]):(props.column.field === 'container_no')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("NO") + " : ")+" "+_vm._s(props.row.container_no != null && props.row.container != null ? props.row.container.container : props.row.container_no_manual)+" ")]):(props.column.field === 'action')?_c('span',[_c('b-button',{staticClass:"px-50 mx-25",attrs:{"variant":"success","size":"sm","to":{
                name: 'transport.waybills.view',
                params: { id: props.row.notification.id },
              }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("View")))])],1),_c('b-button',{staticClass:"px-50",attrs:{"variant":"info","size":"sm","to":{
                name: 'transport.waybills.tracking',
                params: { id: props.row.notification.id },
              }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"ActivityIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Tracking")))])],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("Showing 1 to"))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '25', '50']},on:{"input":_vm.onPerPageChange},model:{value:(_vm.serverParams.perPage),callback:function ($$v) {_vm.$set(_vm.serverParams, "perPage", $$v)},expression:"serverParams.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("of"))+" "+_vm._s(_vm.paginateData.total)+" "+_vm._s(_vm.$t("entries"))+" ")])],1),_c('div',[_c('pagination',{staticClass:"mt-1 mb-0",attrs:{"data":_vm.paginateData,"limit":2},on:{"pagination-change-page":_vm.onPageChange}})],1)])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"align-middle",attrs:{"variant":"danger"}})],1)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }