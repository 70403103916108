<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-5">
      <div class="m-2"></div>
      <div class="m-1">
        <!-- table -->
        <vue-good-table
          :columns="tableColumns"
          :rows="paginateData.data"
          :pagination-options="{
            enabled: true,
          }"
          mode="remote"
          :line-numbers="false"
          theme="polar-bear"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          :isLoading.sync="loading"
        >
          <!-- loading -->
          <template slot="loadingContent">
            <b-spinner
              variant="danger"
              v-show="loading"
              class="align-middle"
            ></b-spinner>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'client_id'">
              {{ props.row.client ? props.row.client.name_ar : "" }}
            </span>
            <span v-else-if="props.column.field === 'container_no'">
              {{ $t("NO") + " : " }}
              {{
                props.row.container_no != null && props.row.container != null
                  ? props.row.container.container
                  : props.row.container_no_manual
              }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                variant="success"
                size="sm"
                class="px-50 mx-25"
                :to="{
                  name: 'transport.waybills.view',
                  params: { id: props.row.notification.id },
                }"
              >
                <feather-icon icon="EyeIcon" class="mr-25" />
                <span>{{ $t("View") }}</span>
              </b-button>

              <b-button
                variant="info"
                size="sm"
                class="px-50"
                :to="{
                  name: 'transport.waybills.tracking',
                  params: { id: props.row.notification.id },
                }"
              >
                <feather-icon icon="ActivityIcon" class="mr-25" />
                <span>{{ $t("Tracking") }}</span>
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> {{ $t("Showing 1 to") }} </span>

                <b-form-select
                  v-model="serverParams.perPage"
                  :options="['10', '25', '50']"
                  class="mx-1"
                  @input="onPerPageChange"
                />
                <span class="text-nowrap">
                  {{ $t("of") }} {{ paginateData.total }} {{ $t("entries") }}
                </span>
              </div>
              <div>
                <pagination
                  :data="paginateData"
                  :limit="2"
                  @pagination-change-page="onPageChange"
                  class="mt-1 mb-0"
                >
                </pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";

import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BPaginationNav,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";

export default {
  data() {
    return {
      paginateData: {},
      tableColumns: [],
      serverParams: {
        columnFilters: {},
        is_goods_arrive: "0",
        sortField: "serial",
        sortType: "DESC",
        perPage: 10,
        page: 1,
      },
      loading: false,
    };
  },

  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BPaginationNav,
    BFormSelect,
    BSpinner,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
  },
  created() {
    this.$store.commit("START_LOADING");
    this.loadItems();
  },
  methods: {
    loadItems() {
      this.$http
        .get("/crm/transport-notifications", {
          params: this.serverParams,
        })
        .then((response) => {
          this.paginateData = response.data.notifications;

          this.setTableColumn();
        })
        .finally(() => {
          this.$store.commit("STOP_LOADING");
        });
    },
    setTableColumn() {
      this.tableColumns = [
        {
          label: this.$t("Action"),
          field: "action",
          width: "185px",
          sortable: false,
        },
        {
          label: this.$t("Code"),
          field: "notification.serial",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("Code"),
          },
        },
        {
          label: this.$t("notification_type"),
          field: "notification_type.name",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("notification_type"),
          },
        },
        {
          label: this.$t("board_number"),
          field: "car.board_number",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("board_number"),
          },
        },
        {
          label: this.$t("driver"),
          field: "driver.name_ar",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("driver"),
          },
        },
        {
          label: this.$t("from_city"),
          field: "city_from.name",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("from_city"),
          },
        },
        {
          label: this.$t("to_city"),
          field: "city_to.name",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("to_city"),
          },
        },
        {
          label: this.$t("goods_type"),
          field: "notification.goods_type.name",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("goods_type"),
          },
        },

        {
          label: this.$t("request_date"),
          field: "notification.request_date",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("request_date"),
          },
        },
        {
          label: this.$t("tonnage_date"),
          field: "notification.tonnage_date",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("tonnage_date"),
          },
        },

        {
          label: this.$t("permission_no"),
          field: "notification.permission_no",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("permission_no"),
          },
        },
        {
          label: this.$t("policy_number"),
          field: "notification.policy_number",

          filterOptions: {
            enabled: true,
            placeholder: this.$t("policy_number"),
          },
        },
      ];
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    onPageChange(page) {
      this.updateParams({ page: page });
    },
    onPerPageChange(perPage) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: perPage });
    },
    onSortChange(params) {
      this.updateParams({
        sortType: params[0].type,
        sortField: params[0].field,
      });
    },
    onColumnFilter(params) {
      this.updateParams(params);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
</style>
